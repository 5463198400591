import axios from "axios";
import { env } from "../env";
import { loginRequest, msalInstance } from "../authConfig";
import { CacheLookupPolicy } from "@azure/msal-browser";

const API_BASE_URL = env.REACT_APP_BASE_URL + "/optimization/api/";

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("accessToken");
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    // Optional: Do something with response data
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    var silentRequest: any = {
      scopes: loginRequest.scopes,
      forceRefresh: false,
      cacheLookupPolicy: CacheLookupPolicy.Default, // will default to CacheLookupPolicy.Default if omitted
    };

    if (error.response.status === 401) {
      if (!originalConfig._retry) {
        try {
          let tokenResponse = await msalInstance.acquireTokenSilent(
            silentRequest
          );
          if (tokenResponse && tokenResponse.hasOwnProperty("accessToken")) {
            localStorage.setItem(
              "accessToken",
              tokenResponse.accessToken || ""
            );
            // Axios has a problem to retry POST multipart type request.
            if (
              originalConfig.url === "v1/jobs" &&
              originalConfig.method === "post"
            ) {
              originalConfig.headers["content-type"] = "multipart/form-data";
            }
            return api(originalConfig);
          }
        } catch (_error: any) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          } else {
            localStorage.removeItem("accessToken");
            msalInstance
              .logoutRedirect({
                postLogoutRedirectUri: `${window.location.href}`,
              })
              .then(
                (data) => {
                  localStorage.removeItem("user");
                  localStorage.removeItem("accessToken");
                },
                (error) => {}
              );
          }
          return Promise.reject(_error);
        }
      } else {
        localStorage.removeItem("accessToken");
        window.location.href = "/";
      }
    }
    // Do whatever you want with the response error here:

    // But, be SURE to return the rejected promise, so the caller still has
    // the option of additional specialized handling at the call-site:
    return Promise.reject(error);
  }
);
