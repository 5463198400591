import {createColumnHelper} from "@tanstack/react-table";
import {Box} from "@mui/material";
import format from "date-fns/format";
import styled from "@emotion/styled";
import GeneralStatusDot from "../../components/general-status-dot/general-status-dot";
import DownloadZipReport from "../../components/download-zip-report/download-zip-report";
import CancelJob from "../../components/cancel-job/cancel-job";
import {store} from "../../store/store";
import CopyJob from "../../components/copy-job/copy-job";
import RestartJob from "../../components/restart-job/restart-job";

type Record = {
  pk: number;
  name: string;
  start_date_time: string;
  end_date_time: string;
  status: any;
  notes: string;
  actions: number;
  possible_restart: boolean;
};

let IconContainer = styled(Box)<any>`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-left: 2px;
    margin-right: 2px;
`;

const columnHelper = createColumnHelper<Record>();
export const overviewColumns = [
  columnHelper.accessor("name", {
    cell: (info) => <Box textAlign={"center"}>{info.getValue()}</Box>,
    footer: (info) => info.column.id,
    minSize: 100,
    meta: {
      label: "Name",
      headerLabelAlignment: "center",
      sortKey: "name",
    },
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.start_date_time, {
    id: "start_date_time",
    cell: (info) => {
      return (
        <>
          {info.getValue()
            ? format(new Date(info.getValue()), "MM/dd/yyyy hh:mm a")
            : "-"}
        </>
      );
    },
    // header: () => <span>Start Date</span>,
    meta: {
      label: "Start Date",
    },
    footer: (info) => info.column.id,
    minSize: 190,
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.end_date_time, {
    id: "end_date_time",
    cell: (info) => {
      return (
        <>
          {info.getValue()
            ? format(new Date(info.getValue()), "MM/dd/yyyy hh:mm a")
            : "-"}
        </>
      );
    },
    // header: () => <span>End Date</span>,
    footer: (info) => info.column.id,
    minSize: 190,
    meta: {
      label: "End Date",
    },
    enableSorting: true,
  }),
  columnHelper.accessor((row) => row.status, {
    id: "status",
    cell: (info) => {
      let status = info.getValue();
      let jobStatusEnums = store.getState().core.enumObject["job_statuses"];
      let enumObject = jobStatusEnums.find((x: any) => x.value === status);

      return (
        //@ts-ignore
        <GeneralStatusDot
          color={enumObject["color"]}
          label={enumObject["name"]}
        />
      );
    },
    // header: () => <span>Status</span>,
    footer: (info) => info.column.id,
    minSize: 150,
    meta: {
      label: "Status",
      sortKey: "status__status",
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.notes, {
    id: "notes",
    cell: (info) => {
      return info.getValue();
    },
    // header: () => <span>Notes</span>,
    footer: (info) => info.column.id,
    minSize: 200,
    meta: {
      label: "Notes",
    },
    enableSorting: false,
  }),
  columnHelper.accessor((row) => row.actions, {
    id: "actions",
    cell: (info) => {
      let canSeeCopyButton =
        store.getState().core?.tenantDetails?.configuration?.can_copy_job;
      let canRestartJob = info?.row?.original?.possible_restart;
      let canSeeSettingsButton = false;
      let buttons = null;
      switch (info.row.original.status) {
        case "DRAFT": {
          buttons = <></>;
          break;
        }
        case "PROCESSING": {
          buttons = (
            <IconContainer>
              <CancelJob job={info.row.original}/>
            </IconContainer>
          );
          break;
        }
        case "WRITING_OUTPUTS": {
          buttons = (
            <IconContainer>
              <CancelJob job={info.row.original}/>
            </IconContainer>
          );
          break;
        }
        case "OPTIMIZING": {
          buttons = (
            <IconContainer>
              <CancelJob job={info.row.original}/>
            </IconContainer>
          );
          break;
        }
        case "COMPLETED": {
          buttons = (
            <Box display={"flex"}>
              <IconContainer>
                <DownloadZipReport job={info.row.original}/>
              </IconContainer>
              {canSeeCopyButton && (
                <IconContainer>
                  <CopyJob job={info.row.original}/>
                </IconContainer>
              )}
            </Box>
          );
          break;
        }
        case "ERROR": {
          buttons = (
            <>
              {canSeeCopyButton && (
                <IconContainer>
                  <CopyJob job={info.row.original}/>
                </IconContainer>
              )}
              {/*<CustomLink data-custom-action={"download-error-report"}>*/}
              {/*  Download Error Report*/}
              {/*</CustomLink>*/}
            </>
          );
          break;
        }
        case "CANCELED": {
          buttons = (
            <>
              {canSeeCopyButton && (
                <IconContainer>
                  <CopyJob job={info.row.original}/>
                </IconContainer>
              )}
            </>
          );
          break;
        }
      }
      return (
        <Box display={"flex"} flexDirection={"row"}>
          {buttons}
          {canRestartJob && (
            <IconContainer>
              <RestartJob job={info.row.original}/>
            </IconContainer>
          )}
        </Box>
      );
    },
    // header: () => <span>Actions</span>,
    footer: (info) => info.column.id,
    minSize: 100,
    meta: {
      label: "Actions",
    },
    enableSorting: false,
  }),
];
